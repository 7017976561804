"use client";

import { createInstance } from "@optimizely/react-sdk";

export const createOptimizelyInstance = () => {
    return createInstance({
        sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
        logger: {
            log: (_, message) => {
                if (
                    message.match(/Feature key .* is not in datafile/) === null
                ) {
                    // This matches the format they send their errors in
                    // eslint-disable-next-line no-console
                    console.error(
                        `[OPTIMIZELY] - ERROR ${new Date().toISOString()} ${message}`,
                    );
                }
            },
        },
        logLevel: "error",
    });
};
